import React from 'react';
import './icon.scss';
import angle from '../../assets/angle.svg';
import check from '../../assets/check.svg';
import checked from '../../assets/checked.svg';
import info from '../../assets/info.svg';
import inProgress from '../../assets/in-progress.svg';
import met from '../../assets/met.svg';
import notMet from '../../assets/not-met.svg';
import noData from '../../assets/no-data.svg';
import help from '../../assets/help.svg';
import search from '../../assets/search.svg';
import triangle from '../../assets/triangle.svg';
import sortUp from '../../assets/sort-up.svg';
import sortDown from '../../assets/sort-down.svg';
import caretLeft from '../../assets/caret-left.svg';
import caretRight from '../../assets/caret-right.svg';
import chevronDown from '../../assets/chevron-down.svg';
import pencil from '../../assets/pencil.svg';

export type IconColor = 'blue' | 'green' | 'grey';

export interface Props {
  name: string;
  color?: IconColor;
  borderColor?: 'lightblue';
  size?: 16 | 24;
}

export type IconSvg =
  | 'angle'
  | 'check'
  | 'checked'
  | 'help'
  | 'info'
  | 'in-progress'
  | 'met'
  | 'not-met'
  | 'no-data'
  | 'search'
  | 'triangle'
  | 'sort'
  | 'chevronDown'
  | 'pencil';

export const svgs: Record<string, string> = {
  angle,
  check,
  checked,
  help,
  info,
  'in-progress': inProgress,
  met,
  'not-met': notMet,
  'no-data': noData,
  search,
  triangle,
  sortUp,
  sortDown,
  caretLeft,
  caretRight,
  chevronDown,
  pencil,
};

export const Icon = (props: Props): JSX.Element => {
  const { name, color, borderColor, size = 24 } = props;
  (Icon as React.FC).displayName = `Icon-${name}`;

  const borderClass = borderColor ? `srt-icon srt-icon-border` : '';
  const colorClass = color ? `${color}` : '';
  const sizeClass = size === 24 ? 'twenty-four-px' : 'sixteen-px';

  const svg: string = (svgs as unknown as [])[name as unknown as number];

  return (
    <React.Fragment>
      <div
        className={`${borderClass} srt-icon-inner ${colorClass} ${sizeClass}`}
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    </React.Fragment>
  );
};
