import { apiClient, getUserContextState } from '../utils';
import { GET_STUDENT_DETAILS_ENDPOINT } from '../constants';

export interface APIRequestParams {
  classYear?: string;
  highschoolId?: string;
  indicator?: string;
  counselorId?: string;
  userLevel?: string;
}
//TODO:: make this not re-retrieve student details if in same school and class year
export const getStudentDetails = async (params: APIRequestParams) => {
  const state = getUserContextState();
  return await apiClient({
    url: GET_STUDENT_DETAILS_ENDPOINT,
    method: 'GET',
    params: {
      state: state,
      ...params,
    },
  });
};
