const css = `.notice-container {
  display: block;
  position: relative;
  margin-top: 7%;
}

.notice-icon-container {
  display: flex;
  position: relative;
  justify-content: space-around;
  padding: 30px;
}

.notice-icon {
  margin: 5px;
}

.notice {
  display: block;
  position: relative;
  padding: 20px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 27px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL05vdGljZSIsInNvdXJjZXMiOlsibm90aWNlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
