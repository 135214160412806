import { apiClient } from '../utils/apiClient';
import { GET_COUNSELORS_ENDPOINT } from '../constants';
import { Counselor } from '../../../interfaces/api';
import { DropdownItem } from '../components/Dropdown';

export const getCounselors = async (): Promise<DropdownItem[]> => {
  const counselors = await apiClient({ url: GET_COUNSELORS_ENDPOINT, method: 'GET' });
  const counselorsArray = counselors.map((c: Counselor) => {
    const newCounselor: DropdownItem = {
      key: `${c.contact_id}`,
      value: `${c.first_name} ${c.last_name}`,
    };
    return newCounselor;
  });
  counselorsArray.unshift({
    key: '0',
    value: 'All Counselors',
  });
  return counselorsArray;
};
