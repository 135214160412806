const css = `.srt-main {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "Open Sans", sans-serif;
}

.srt-banner {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 27px;
  padding: 15px 10px 10px 15px;
  border-bottom: 1px solid #aaaaaa;
}

.srt-app-name-info {
  display: flex;
}

.banner-dropdowns {
  display: flex;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb250YWluZXJzL1BhZ2UiLCJzb3VyY2VzIjpbInBhZ2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
