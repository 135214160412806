export const APP_DISPLAY_NAME = 'Student Readiness Indicators';

export const SUCCEED_PROPS = () => {
  return (window as unknown as any).SRI_STAFF_UI_CONTEXT?.props;
};

export const MAIN_INFO_TEXT = 'TBD';

export enum STATUS {
  ALL = 'all',
  MET = 'met',
  IN_PROGRESS = 'in-progress',
  NOT_MET = 'not-met',
  NO_DATA = 'no-data',
}

export const STATUSES = [
  { key: STATUS.MET, value: 'Met' },
  { key: STATUS.IN_PROGRESS, value: 'In Progress' },
  { key: STATUS.NOT_MET, value: 'Not Met' },
  { key: STATUS.NO_DATA, value: 'No Data' },
];
