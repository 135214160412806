import React from 'react';
import './barGraph.scss';
import { BarGraphDataItem } from '../../containers/Card';

export interface BarGraphProps {
  data: BarGraphDataItem[];
  title: string;
}

export interface BarGroupProps {
  barInfo: BarGraphDataItem;
}

const BAR_HEIGHT = 27;
const BAR_PADDING = 10;
const TOTAL_BAR_HEIGHT = BAR_HEIGHT + BAR_PADDING;
const COMPLETE_BAR_WIDTH = 203;
const BAR_WIDTH_FROM_PERCENTAGE = (percentage: number) => (percentage / 100) * COMPLETE_BAR_WIDTH;
const BAR_GRAPH_WIDTH = 500;
const BAR_GRAPH_HEIGHT = (totalItems: number) => totalItems * TOTAL_BAR_HEIGHT + 50;
const X_AXIS_START_PIXEL = 10;
const Y_AXIS_START_PIXEL = -1;
const X_AXIS_LENGTH = 260;
const Y_AXIS_LENGTH = (barChartHeight: number) => barChartHeight - 48;

export const BarGroup = (props: BarGroupProps) => {
  (BarGroup as React.FC).displayName = 'BarGroup';
  const {
    barInfo: { name, value },
  } = props;
  const width = BAR_WIDTH_FROM_PERCENTAGE(value);
  const yMid = TOTAL_BAR_HEIGHT * 0.5;
  const xMid = -15;

  return (
    <g className="bar-group">
      <switch>
        <foreignObject
          x={-167}
          y={BAR_HEIGHT * 0.3}
          width="170"
          height={TOTAL_BAR_HEIGHT}
          alignmentBaseline="middle"
        >
          <p title={name} className="name-label-p">
            {name}
          </p>
        </foreignObject>
        <text className="name-label" x={xMid} y={yMid} alignmentBaseline="middle">
          {name}
        </text>
      </switch>
      <rect
        className="bar"
        x={X_AXIS_START_PIXEL + 1}
        y={BAR_PADDING * 0.5}
        width={width}
        height={BAR_HEIGHT}
      />
      <text className="value-label" x={width + 45} y={yMid} alignmentBaseline="middle">
        {value}%
      </text>
    </g>
  );
};

export const BarGraph = (props: BarGraphProps) => {
  (BarGraph as React.FC).displayName = 'BarGraph';
  const { data, title } = props;
  const svgHeight = BAR_GRAPH_HEIGHT(data?.length);
  const yAxisLength = Y_AXIS_LENGTH(svgHeight);

  const xAxisY = Y_AXIS_START_PIXEL + yAxisLength;

  return (
    <>
      <p className="title">{title}</p>
      <svg width={BAR_GRAPH_WIDTH} height={svgHeight}>
        <defs>
          <linearGradient id="LinearGradientBar" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="#00B6EF" />
            <stop offset="100%" stopColor="#5CD9FF" />
          </linearGradient>
        </defs>
        <g transform="translate(170, 30)">
          <line
            className="line"
            x1={X_AXIS_START_PIXEL}
            y1={xAxisY}
            x2={X_AXIS_LENGTH}
            y2={xAxisY}
          />
          {data?.map((element: BarGraphDataItem, index: number) => (
            <g transform={`translate(0, ${index * TOTAL_BAR_HEIGHT})`} key={index}>
              <BarGroup barInfo={element} />
            </g>
          ))}
          <line
            className="line"
            x1={X_AXIS_START_PIXEL}
            y1={Y_AXIS_START_PIXEL}
            x2={X_AXIS_START_PIXEL}
            y2={yAxisLength}
          />
        </g>
      </svg>
    </>
  );
};
