const css = `.toastr {
  position: absolute;
  right: 1%;
  z-index: 9;
  padding: 10px;
  transition: opacity 0.6s;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1px;
  width: 511px;
}
.toastr.error {
  background: #fde7e7;
  border: 1px solid #eb1313;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.toastr.success {
  background: #d9fcf2;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  top: 8px;
}
.toastr.info {
  background: #e5f3ff;
  border: 1px solid #0075db;
  box-sizing: border-box;
  border-radius: 4px;
}
.toastr.warning {
  background: #feefe7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom: 2px solid #f5691d;
}
.toastr .toastr-icon {
  margin-top: 2px;
  padding-right: 5px;
  margin-left: 5px;
}
.toastr .toastr-border-left {
  position: absolute;
  width: 3px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #10d097;
}
.toastr .toastr-title {
  margin-left: 5px;
}
.toastr .toastr-message {
  margin-left: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}
.toastr .toastr-message.success {
  width: 80%;
}
.toastr .toastr-close {
  margin-left: 40px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL1RvYXN0ciIsInNvdXJjZXMiOlsidG9hc3RyLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0o7RUFDRSJ9 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
