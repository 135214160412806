import './overview.scss';
import React from 'react';
import { BarGraphDataItem } from '../../containers/Card';
import { BarGraph } from '../Graph/barGraph';
import { DropdownItem } from '../Dropdown';

export interface OverviewProps {
  indicator: DropdownItem;
  isDistrictLevel: boolean;
  selectedSchool?: DropdownItem;
  selectedClassYear?: DropdownItem;
  selectedCounselor?: DropdownItem;
  overallPercentageMet?: number;
  schoolsOrCounselorsGraphData: BarGraphDataItem[];
  indicatorsGraphData: BarGraphDataItem[];
  schoolsOrCounselorsGraphTitle: string;
  indicatorsGraphTitle: string;
}

export const Overview = (props: React.PropsWithChildren<OverviewProps>): JSX.Element => {
  const {
    isDistrictLevel,
    indicator,
    selectedSchool,
    selectedClassYear,
    selectedCounselor,
    schoolsOrCounselorsGraphData,
    schoolsOrCounselorsGraphTitle,
    indicatorsGraphTitle,
    indicatorsGraphData,
    overallPercentageMet,
  } = props;
  const selectedSchoolOrCounselorOption = isDistrictLevel ? selectedSchool : selectedCounselor;
  const cardContentClass = indicatorsGraphData?.length ? 'start-card-content-inner' : '';

  return (
    <>
      <div className="overview-wrapper">
        <div className="overview-percentage">{overallPercentageMet}%</div>
        <p className="overview-details">
          <span>Met {indicator?.value}</span>
          <span>{selectedSchoolOrCounselorOption?.value}</span>
          {selectedClassYear?.value}
        </p>
      </div>
      <div className={`card-content-inner ${cardContentClass}`}>
        {Boolean(schoolsOrCounselorsGraphData?.length) && (
          <div className="bar-graph" data-cy="bar-graph-schools-counselors">
            <BarGraph data={schoolsOrCounselorsGraphData} title={schoolsOrCounselorsGraphTitle} />
          </div>
        )}
        {Boolean(indicatorsGraphData?.length) && (
          <div className="bar-graph" data-cy="bar-graph-indicators">
            <BarGraph data={indicatorsGraphData} title={indicatorsGraphTitle} />
          </div>
        )}
      </div>
    </>
  );
};
