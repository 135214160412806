const css = `.labeled-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.labeled-icon :first-child {
  margin-right: 6px;
}
.labeled-icon .label-name {
  font-family: Open Sans, sans-serif !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL0xhYmVsZWRJY29uIiwic291cmNlcyI6WyJsYWJlbGVkLWljb24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUdGO0VBQ0UifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
