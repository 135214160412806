import { apiClient } from '../utils/apiClient';
import { GET_SCHOOLS_ENDPOINT } from '../constants';
import { School } from '../../../interfaces/api';
import { DropdownItem } from '../components/Dropdown';

export const getSchools = async () => {
  const schools = await apiClient({ url: GET_SCHOOLS_ENDPOINT, method: 'GET' });
  const schoolsArray = schools.map((s: School) => {
    const newSchool: DropdownItem = {
      key: `${s.nid}`,
      value: `${s.name}`,
    };
    return newSchool;
  });
  schoolsArray.unshift({
    key: '0',
    value: 'All Schools',
  });
  return schoolsArray;
};
