const css = `.srt-modal {
  position: fixed;
  flex: none;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.srt-modal-visible-true {
  display: block;
}

.srt-modal-visible-false {
  display: none;
}

.srt-modal-content {
  position: fixed;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 25px;
  border: 1px solid #888;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.srt-modal-close {
  position: fixed;
  color: #aaaaaa;
  top: 5px;
  right: 10px;
  font-size: 30px;
  font-weight: bold;
}

.srt-modal-close:hover,
.srt-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL01vZGFsIiwic291cmNlcyI6WyJtb2RhbC5zY3NzIiwiLi4vLi4vZGVmaW5pdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQSxPQ2hDSztFRGlDTDtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7QUFBQTtFQUVFO0VBQ0E7RUFDQSJ9 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
