import axios from 'axios';
import { BASE_API_URL } from '../constants';

// UI code expects to find `config.json` containing the `apiUrl` for use in connecting to the backend API.
// These are automatically set in the deployment process.
// When running locally the config.json is proxied via /esbuild.ts
const getApiUrl = async () => {
  const { data: config } = await axios.get('/config.json');
  return `${config.apiUrl}`;
};

export const apiClient = async (configs: object) => {
  // use the embedding app's window context global for the API_URL if it exists
  const useAxios = axios.create({
    baseURL: BASE_API_URL() ? BASE_API_URL() : await getApiUrl(),
  });
  const response = await useAxios.request(configs);
  return response.data;
};
