import './modal.scss';
import React from 'react';

export interface Props {
  text?: string;
  isVisible: boolean;
  toggleVisibility: () => void;
}

export const Modal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { text, isVisible, toggleVisibility, children } = props;

  (Modal as React.FC).displayName = 'Modal';
  return (
    <div
      className={`srt-modal srt-modal-visible-${isVisible}`}
      data-cy={`modal-visible-${isVisible}`}
    >
      <div className="srt-modal-content">
        <span className="srt-modal-close" onClick={() => toggleVisibility()}>
          &times;
        </span>
        {text ? <p>{text}</p> : children}
      </div>
    </div>
  );
};
