import './exportDropdown.scss';
import { Dropdown, DropdownItem } from '../Dropdown';
import React, { useState } from 'react';

export const ExportDropdown = (): JSX.Element => {
  (ExportDropdown as React.FC).displayName = 'ExportDropdown';

  const exportChoices = [
    { key: 'Export All Data', value: 'Export All Data' },
    { key: 'Export Data in Current View', value: 'Export Data in Current View' },
  ];

  const [exportChoice, setExportChoice] = useState<DropdownItem>(exportChoices[0]);

  return (
    <div className="export-dropdown">
      <Dropdown
        withDivider
        size="x-small"
        color="blue"
        list={exportChoices}
        placeholder={'Export'}
        getSelected={setExportChoice}
        iconColor={'blue'}
        placeHolderColor="blue"
      />
    </div>
  );
};
