import './labeled-icon.scss';
import React from 'react';

import { Icon } from '../Icon';

export interface Props {
  iconName: string;
  label?: string;
  labelSide?: 'right' | 'left';
}

export const LabeledIcon = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (LabeledIcon as React.FC).displayName = 'LabeledIcon';
  const { iconName, label, labelSide = 'left' } = props;

  return (
    <div className="labeled-icon">
      {label && labelSide === 'left' && <div> {label} </div>}
      <Icon name={iconName} />
      {label && labelSide === 'right' && <div> {label} </div>}
    </div>
  );
};
