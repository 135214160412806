const css = `.student-details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 50px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 7px;
}

.status-key {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  white-space: nowrap;
}

.student-search-and-status-key-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-key-container {
  display: flex;
  width: 88px;
  justify-content: space-between;
  margin-right: 12px;
}

.status-key-popover-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 5px;
  position: static;
  width: 188px;
  left: calc(50% - 94px);
  top: 0;
  bottom: 0;
  /* Common/Input Default White */
  background: white;
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #838e91;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0;
}

.status-key-labeled-icon {
  margin: 14px 0 14px 5px;
}

.search-status-table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL1N0dWRlbnREZXRhaWxzIiwic291cmNlcyI6WyJzdHVkZW50LWRldGFpbHMuc2NzcyIsIi4uLy4uL2RlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0EsT0NYaUI7RURZakI7OztBQUdGO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBRUE7RUFDQTtFQUNBO0VBQ0E7RUFDQTtBQUVBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7QUFFQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
