export const STUDENT_TABLE_DEFAULT_COLUMNS: any = [
  {
    Header: 'Student Name',
    accessor: 'studentName',
    width: 100,
    sticky: 'left',
  },
  {
    Header: 'ID',
    accessor: 'id',
    width: 100,
    sticky: 'left',
  },
];

export const STUDENT_TABLE_DISTRICT_COLUMNS: any = [
  {
    Header: 'School',
    accessor: 'school',
    width: 100,
    sticky: 'left',
  },
];

export const STUDENT_TABLE_SCHOOL_COLUMNS: any = [
  {
    Header: 'Counselor',
    accessor: 'counselor',
    width: 100,
    sticky: 'left',
  },
];
