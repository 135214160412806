export const BASE_API_URL = () => {
  return (window as unknown as any).SRI_STAFF_UI_CONTEXT?.API_URL;
};

export const GET_DATA_FIELDS_ENDPOINT = '/dataFields';
export const GET_SCHOOLS_ENDPOINT = '/schools';
export const GET_COUNSELORS_ENDPOINT = '/counselors';
export const GET_CLASS_YEARS_ENDPOINT = '/findClassYears';
export const GET_OVERVIEW_DATA_ENDPOINT = '/overview';
export const GET_STUDENT_DETAILS_ENDPOINT = '/studentDetails';
