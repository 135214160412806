import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';

import './card.scss';

import { CardBanner } from '../../components/CardBanner';
import { DropdownItem } from '../../components/Dropdown';
import { Footer } from '../../components/Footer';
import { Overview } from '../../components/Overview';
import { StudentDetails } from '../../components/StudentDetails';
import {
  STUDENT_TABLE_DEFAULT_COLUMNS,
  STUDENT_TABLE_DISTRICT_COLUMNS,
  STUDENT_TABLE_SCHOOL_COLUMNS,
} from '../../constants';
import {
  Indicator,
  getOverviewData,
  getIndicators,
  studentTableIndicatorColumns,
  getStudentDetails,
} from '../../api';

export interface Props {
  isDistrictLevel: boolean;
  selectedSchool?: DropdownItem;
  selectedCounselor?: DropdownItem;
  selectedClassYears?: DropdownItem;
  schools?: DropdownItem[];
  counselors?: DropdownItem[];
  selectedClassYear?: DropdownItem;
}

export interface BarGraphDataItem {
  id: string;
  name: string;
  value: number;
}

export interface StudentTableColumn {
  Header: string;
  accessor: string;
  width?: number;
  sticky?: string;
}

const tabs = ['Overview', 'Student Details'];

export const Card = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (Card as React.FC).displayName = 'Card';
  const {
    isDistrictLevel,
    selectedSchool,
    selectedCounselor,
    selectedClassYears,
    schools,
    counselors,
    selectedClassYear,
  } = props;
  const schoolOrDistrictClass = isDistrictLevel ? `srtDistrict` : `srtSchool`;
  const emptyIndicators: Indicator[] = [];

  const [{ indicators, indicatorsMap }, setIndicators] = useState({
    indicators: emptyIndicators,
    indicatorsMap: {},
  });
  const [indicator, setIndicator] = useState<DropdownItem>(indicators[0]);
  const [tab, setSelectedTab] = useState<string>(tabs[0]);
  const [overallPercentage, setOverallPercentage] = useState<number>(0);
  const [schoolsOrCounselorsGraphData, setSchoolsOrCounselorsGraphData] = useState<
    BarGraphDataItem[]
  >([]);
  const [indicatorsGraphData, setIndicatorsGraphData] = useState<BarGraphDataItem[]>([]);
  const [schoolsOrCounselorsGraphTitle, setSchoolsOrCounselorsGraphTitle] = useState<string>('');
  const [indicatorsGraphTitle, setIndicatorsGraphTitle] = useState<string>('');
  // For student table
  const [schoolOrCounselorTableColumns, setSchoolOrCounselorTableColumns] = useState<Column<any>[]>(
    []
  );
  const [schoolOrCounselorTableData, setSchoolOrCounselorTableData] = useState<unknown[]>([]);
  const studentsAndOverviewAPIParams = {
    classYear: selectedClassYear?.key,
    highschoolId: selectedSchool?.key,
    indicator: indicator?.key as string,
    counselorId: selectedCounselor?.key,
    userLevel: isDistrictLevel ? 'District' : 'Highschool', // This should be fetched on BE using user session, remove this once APIs imp is done
  };

  const setStudentDetailsTabData = async (searchQuery?: string) => {
    if (!searchQuery) {
      const studentDetails = await getStudentDetails(studentsAndOverviewAPIParams);
      const { key, value } = isDistrictLevel
        ? (selectedSchool as DropdownItem)
        : (selectedCounselor as DropdownItem);
      const schoolOrCounselorHeader = isDistrictLevel ? 'school' : 'counselor';
      // there used to be mutlitple and single versions of student details which does not make sense
      // if there is any case where we would get back only 1 and we need an array, just put the single
      // item into an array
      const tableData: unknown[] =
        key === '0'
          ? studentDetails
          : studentDetails.map((row: unknown) => {
              return {
                ...(row as unknown as any),
                ...{
                  [schoolOrCounselorHeader]: value,
                },
              };
            });
      setSchoolOrCounselorTableData(tableData);
    }
    const tableColumns = [
      ...STUDENT_TABLE_DEFAULT_COLUMNS,
      ...(isDistrictLevel ? STUDENT_TABLE_DISTRICT_COLUMNS : STUDENT_TABLE_SCHOOL_COLUMNS),
      ...studentTableIndicatorColumns(indicators, indicator?.key as string),
    ];
    setSchoolOrCounselorTableColumns(tableColumns);
  };

  const getAndParseIndicators = async () => {
    const indicatorsMap: object = await getIndicators();
    const indicatorsArray: Indicator[] = Object.values(indicatorsMap);

    setIndicators({ indicators: indicatorsArray, indicatorsMap });
    const [firstIndicator] = indicatorsArray;
    if (firstIndicator) {
      setIndicator(firstIndicator as Indicator);
    }
  };

  const setOverviewTabData = async () => {
    const overviewData = await getOverviewData(studentsAndOverviewAPIParams);
    const schoolOrCounselor = isDistrictLevel ? `School` : `Counselor`;

    setOverallPercentage(overviewData.totalPercentageMet);
    setIndicatorsGraphTitle(`Students Meeting ${indicator.value}, by Indicator`);
    setSchoolsOrCounselorsGraphTitle(
      `Students Meeting ${indicator.value}, by ${schoolOrCounselor}`
    );
    setSchoolsOrCounselorsGraphData(overviewData.schoolsData);

    const overviewDataWithNames = overviewData?.indicatorsData?.map((indicator: Indicator) => {
      const displayName: string = (indicatorsMap as unknown as any)[indicator.id].value;
      return { ...indicator, name: displayName };
    });

    setIndicatorsGraphData(overviewDataWithNames);
  };

  useEffect(() => {
    if (indicators?.length < 1) {
      // only retrieve indicators once
      getAndParseIndicators();
    } else if (indicator && indicators[0]) {
      if (tab === 'Overview' && (selectedSchool || selectedCounselor) && selectedClassYears) {
        setOverviewTabData();
      }
      if (
        tab === 'Student Details' &&
        (selectedSchool || selectedCounselor) &&
        selectedClassYears
      ) {
        setStudentDetailsTabData();
      }
    }
  }, [
    isDistrictLevel,
    selectedSchool,
    selectedCounselor,
    selectedClassYears,
    indicators,
    indicator,
    tab,
  ]);

  return (
    <div className={`card-wrapper ${schoolOrDistrictClass}`}>
      {indicators && indicator && (
        <div className="card">
          <CardBanner
            indicators={indicators as DropdownItem[]}
            tabs={tabs}
            setIndicator={setIndicator}
            setSelectedTab={setSelectedTab}
          />
          <div className="card-content">
            <div className="indicator-name">{indicator.value}</div>
            {tab === 'Overview' && (
              <Overview
                isDistrictLevel={isDistrictLevel}
                indicator={indicator}
                selectedSchool={selectedSchool}
                selectedCounselor={selectedCounselor}
                selectedClassYear={selectedClassYears}
                schoolsOrCounselorsGraphData={schoolsOrCounselorsGraphData}
                schoolsOrCounselorsGraphTitle={schoolsOrCounselorsGraphTitle}
                indicatorsGraphData={indicatorsGraphData}
                indicatorsGraphTitle={indicatorsGraphTitle}
                overallPercentageMet={overallPercentage}
              />
            )}
            {tab === 'Student Details' && (
              <StudentDetails
                indicator={indicator}
                schoolOrCounselorTableColumns={schoolOrCounselorTableColumns}
                schoolOrCounselorTableData={schoolOrCounselorTableData}
                schools={schools}
                counselors={counselors}
                setStudentDetailsTabData={setStudentDetailsTabData}
              />
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
