/* eslint-disable react/prop-types */
import './App.scss';
import React from 'react';
import { Page, Props } from './containers/Page';
import { APP_DISPLAY_NAME } from './constants';
import { getUserContextProps } from './utils';

const props: Props = { appDisplayName: APP_DISPLAY_NAME, ...getUserContextProps() } as Props;

const App = (): JSX.Element => {
  (App as React.FC).displayName = 'SRI App Container';

  const isDev = location.port === '4212' || location.port === '4202';
  if (isDev) {
    // allow for standalone testing using query params
    if (!props.isDistrictLevel) {
      const cypressIsDistrictLevel =
        new URLSearchParams(location.search).get('isDistrictLevel') || 'false';
      props.isDistrictLevel = JSON.parse(cypressIsDistrictLevel);
    }
    if (!(props as unknown as any).state) {
      if ((window as unknown as any).SRI_STAFF_UI_CONTEXT) {
        (window as unknown as any).SRI_STAFF_UI_CONTEXT.state =
          new URLSearchParams(location.search).get('state') || null;
      } else {
        (window as unknown as any).SRI_STAFF_UI_CONTEXT = {
          state: new URLSearchParams(location.search).get('state') || null,
        };
      }
    }
  }

  return <Page {...props} />;
};

export default App;
