import './cardBanner.scss';
import { Tabs } from '../Tabs';
import { Dropdown, DropdownItem } from '../Dropdown';
import React, { Dispatch, SetStateAction } from 'react';

export interface Props {
  indicators: DropdownItem[];
  tabs: string[];
  setIndicator: Dispatch<SetStateAction<DropdownItem>>;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

export const CardBanner = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (CardBanner as React.FC).displayName = 'CardBanner';
  const { indicators, tabs, setIndicator, setSelectedTab } = props;

  return (
    <div className="card-banner">
      <Tabs tabs={tabs} setSelectedTab={setSelectedTab} />
      <div className="card-banner-right">
        <div className="indicators">Indicators</div>
        <Dropdown
          withDivider
          size="large"
          color="blue"
          iconColor="blue"
          list={indicators}
          getSelected={setIndicator}
        />
      </div>
    </div>
  );
};
