import { apiClient, getUserContextState } from '../utils';
import { GET_OVERVIEW_DATA_ENDPOINT } from '../constants';
import { APIRequestParams } from './studentDetails';

export const getOverviewData = async (params: APIRequestParams) => {
  const state = getUserContextState();
  return apiClient({
    url: GET_OVERVIEW_DATA_ENDPOINT,
    method: 'GET',
    params: {
      state: state,
      ...params,
    },
  });
};
