import './info.scss';
import React, { useState, MouseEvent } from 'react';
import { Icon } from '../Icon';

export interface Props {
  url?: string;
  showInfo?: () => void;
}

export const Info = (props: Props): JSX.Element => {
  (Info as React.FC).displayName = 'Info';
  const { url, showInfo } = props;
  const [isInfoOpen, setInfoOpen] = useState(false);

  const openInfo = (e: MouseEvent) => {
    e.stopPropagation();
    if (showInfo) {
      showInfo();
    } else {
      window.open(url);
    }
    setInfoOpen(!isInfoOpen);
  };

  return (
    <button className="info-icon-container" onClick={(e) => openInfo(e)}>
      <Icon name="info" />
    </button>
  );
};
