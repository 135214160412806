const css = `.confirmation-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.3;
  z-index: 2;
}

.confirmation-container {
  font-family: Open Sans, sans-serif;
  color: #333333;
  position: fixed;
  z-index: 3;
  width: 532px;
  left: 50%;
  top: 154px;
  transform: translate(-50%, 0%);
}

.confirmation-title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  background: #f1f4f4;
  padding: 8px 0px 8px;
  border-radius: 4px 4px 0px 0px;
}

.confirmation-text {
  background: #ffffff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  padding: 15px 15px 44px 15px;
  border-top: 1px solid #c4c9ca;
  border-bottom: 1px solid #c4c9ca;
}

.confirmation-footer {
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  padding: 13.5px 10px;
  display: flex;
  justify-content: end;
}
.confirmation-footer button {
  margin-left: 10px;
  padding: 4px 12px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.confirmation-footer button.button-primary {
  border: 0px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL0NvbmZpcm1hdGlvbiIsInNvdXJjZXMiOlsiY29uZmlybWF0aW9uLnNjc3MiLCIuLi8uLi9kZWZpbml0aW9ucy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSxZQ0NNO0VEQU47RUFDQTs7O0FBRUY7RUFDRSxhQ0VpQjtFRERqQixPQ1JpQjtFRFNqQjtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQSxZQ2pCVztFRGtCWDtFQUNBOzs7QUFFRjtFQUNFLFlDeEJXO0VEeUJYO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRSxZQ2pDVztFRGtDWDtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
