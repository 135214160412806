import { apiClient, getUserContextState } from '../utils';
import { GET_DATA_FIELDS_ENDPOINT } from '../constants';

const GLOBAL_DEPTH = 2;
const flattenedDataItems = {};

interface DataFields {
  field: Indicator;
}

export interface Indicator {
  id: string; // for mapping to the DataFields objects
  key?: string; // same as id
  value?: string; // the Display Name
  name?: string; // same as value
  depth?: number; // Used for DropDown item indent
  hidden?: boolean; // hide in DropDown list
  Header?: string; // same as value
  accessor?: string; // same as id
  parentKey?: string;
  children?: Indicator[];
}

// this makes DropDownItem[] compatible list allowing for indentation and hiding items beyond GLOBAL_DEPTH
const flatten = (item: Indicator, depth: number, parentKey: unknown) => {
  if ((item as unknown as any).children) {
    const key: string = (item as unknown as any).id;
    const value: string = (item as unknown as any).name;
    (flattenedDataItems as unknown as any)[key] = {
      key: key,
      value: value,
      depth: depth,
      hidden: depth >= GLOBAL_DEPTH,
      Header: value,
      accessor: key,
      parentKey: parentKey,
    };
    item.children?.forEach((child: Indicator) => {
      return flatten(child, depth + 1, key);
    });
  } else {
    const key: string = item.id;
    const value: string = item.name || item.id;
    (flattenedDataItems as unknown as any)[key] = {
      key: key,
      value: value,
      depth: depth,
      hidden: depth >= GLOBAL_DEPTH,
      Header: value,
      accessor: key,
      parentKey: parentKey,
    };
  }

  return flattenedDataItems;
};

// this makes the table compatible specifications to allow mapping of the StudentDetails API response using the field IDs
export const studentTableIndicatorColumns = (indicatorsIn: object, inKey: unknown) => {
  const indicators = Object.values(indicatorsIn);
  const currentIndicatorsColumns: Indicator[] = [];
  indicators.forEach((indicator: Indicator) => {
    if (indicator.key === inKey) {
      currentIndicatorsColumns.push(indicator);
    } else if (indicator.parentKey === inKey) {
      currentIndicatorsColumns.push(indicator);
    }
  });

  return currentIndicatorsColumns;
};

export const getIndicators = async () => {
  const state = getUserContextState();
  const dataFields: DataFields = await apiClient({
    url: GET_DATA_FIELDS_ENDPOINT,
    method: 'GET',
    params: {
      state: state || '',
    },
  });

  return flatten(dataFields.field, 0, '');
};
