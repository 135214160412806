const css = `.srt-icon {
  position: relative;
  display: flex;
  background-color: white;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  align-items: center;
  padding: 1px;
}

.srt-icon-border {
  border: 1px solid lightblue;
}

.srt-icon-inner {
  align-items: center;
  cursor: pointer;
}

.twenty-four-px {
  width: 24px;
  height: 24px;
}

.sixteen-px {
  width: 16px;
  height: 16px;
}

.srt-icon-inner > svg {
  position: relative;
  display: inline-block;
  color: white;
  cursor: pointer;
}

.srt-icon-inner.blue > svg > path {
  filter: invert(28%) sepia(43%) saturate(6170%) hue-rotate(195deg) brightness(97%) contrast(100%);
}

.srt-icon-inner.green > svg > path {
  filter: invert(55%) sepia(92%) saturate(402%) hue-rotate(59deg) brightness(93%) contrast(88%);
}

.srt-icon-inner.grey > svg > path {
  filter: invert(48%) sepia(11%) saturate(322%) hue-rotate(141deg) brightness(87%) contrast(84%);
}

.srt-icon-inner.purple > svg > path {
  filter: invert(14%) sepia(97%) saturate(1745%) hue-rotate(230deg) brightness(104%) contrast(132%);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL0ljb24iLCJzb3VyY2VzIjpbImljb24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0UifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
