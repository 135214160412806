import './student-details.scss';
import React, { useEffect, useState } from 'react';
import { Search } from '../Search';
import { DropdownItem } from '../Dropdown';
import { TableWithFixedColumns } from '../../containers/TableWithFixedColumn';
import { LabeledIcon } from '../LabeledIcon';
import { ExportDropdown } from '../ExportDropdown';
import { Icon } from '../Icon';
import { Column, Row } from 'react-table';

export interface StudentDetailsProps {
  indicator: DropdownItem;
  schoolOrCounselorTableColumns: Column<any>[];
  schoolOrCounselorTableData: unknown[];
  schools?: DropdownItem[];
  counselors?: DropdownItem[];
  setStudentDetailsTabData: (searchQuery: string) => void;
}

export const StudentDetails = (
  props: React.PropsWithChildren<StudentDetailsProps>
): JSX.Element => {
  (StudentDetails as React.FC).displayName = 'StudentDetails';

  const {
    indicator,
    schoolOrCounselorTableColumns,
    schoolOrCounselorTableData,
    schools,
    counselors,
    setStudentDetailsTabData,
  } = props;

  const [isStatusKeyPopoverOpen, setStatusKeyPopoverOpen] = useState<boolean>(false);
  const [searchStudentNameOrId, setsearchStudentNameOrId] = useState<string>();
  useEffect(() => {
    if (isStatusKeyPopoverOpen) {
      window.addEventListener('click', closePopover);
    }
  }, [isStatusKeyPopoverOpen]);

  useEffect(() => {
    return () => {
      window.removeEventListener('click', closePopover);
    };
  }, []);

  const searchStudents = (query: string) => {
    console.log(`Query for search student by ID or Name ${query}`);
    query && setStudentDetailsTabData(query);
    setsearchStudentNameOrId(query);
  };

  const togglePopover = (e: React.MouseEvent<HTMLSpanElement>) => {
    setStatusKeyPopoverOpen((prevState) => {
      return !prevState;
    });
    e.stopPropagation();
  };

  const closePopover = (e: MouseEvent) => {
    const statusKeyPopOverElement: HTMLElement | null = document.getElementById(
      'status-key-popover-content-id'
    );
    const target = e.target as HTMLElement;
    try {
      if (
        target === statusKeyPopOverElement ||
        !statusKeyPopOverElement ||
        statusKeyPopOverElement.contains(target)
      ) {
        return;
      }
    } catch (e) {
      if (e instanceof TypeError) {
        window.removeEventListener('click', closePopover);
        setStatusKeyPopoverOpen(false);
        return;
      }
    }
    window.removeEventListener('click', closePopover);
    setStatusKeyPopoverOpen(false);
  };

  const onActionClick = (row: Row) => {
    // TODO
  };

  // search global filter
  let filterSchoolOrCounselorTableData = schoolOrCounselorTableData;
  if (searchStudentNameOrId) {
    filterSchoolOrCounselorTableData = schoolOrCounselorTableData.filter((row: any) => {
      return (
        row.studentName.toLowerCase().includes(searchStudentNameOrId.toLowerCase()) ||
        row.id.toLowerCase().includes(searchStudentNameOrId.toLowerCase())
      );
    });
  }

  return (
    <div className="student-details">
      <ExportDropdown />
      <div className="search-status-table-container">
        <div>
          <div className="student-search-and-status-key-container">
            <Search
              placeholder="Search by Name or ID"
              labelName="Student Search"
              submitQuery={searchStudents}
            />
            <div className="status-key-container">
              <span className="status-key">Status Key</span>
              <span onClick={(e: React.MouseEvent<HTMLSpanElement>) => togglePopover(e)}>
                <Icon name={'help'} />
              </span>
            </div>
          </div>
          {isStatusKeyPopoverOpen && (
            <div className="status-key-popover">
              <div className="status-key-popover-content" id="status-key-popover-content-id">
                <LabeledIcon iconName="no-data" label="No Data" labelSide="right" />
                <LabeledIcon iconName="not-met" label="Not Met" labelSide="right" />
                <LabeledIcon iconName="in-progress" label="In Progress" labelSide="right" />
                <LabeledIcon iconName="met" label="Met" labelSide="right" />
              </div>
            </div>
          )}
        </div>
        <TableWithFixedColumns
          indicator={indicator}
          columns={[
            ...schoolOrCounselorTableColumns,
            {
              Header: 'Actions',
              accessor: 'actions',
              sticky: 'right',
              disableSortBy: true,
            } as any,
          ]}
          data={filterSchoolOrCounselorTableData}
          schools={schools}
          counselors={counselors}
          onAction={onActionClick}
        />
      </div>
    </div>
  );
};
