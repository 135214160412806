const css = `.dd-wrapper {
  display: inline-flex;
  position: relative;
  font-size: 1.6rem;
  user-select: none;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  margin-left: 10px;
}

.dd-x-small {
  width: 95px;
  max-width: 95px;
  min-width: 95px;
}

.dd-x-small {
  width: 95px;
  max-width: 95px;
  min-width: 95px;
}

.dd-small {
  width: 156px;
  max-width: 156px;
  min-width: 156px;
}

.dd-large {
  width: 258px;
  max-width: 258px;
  min-width: 258px;
}

.dd-full {
  width: 100%;
}

.dd-flat {
  line-height: 10px;
  font-size: 14px;
  height: 24px;
  max-height: 24px;
  min-height: 24px;
  width: 100%;
  min-width: 100%;
  margin-left: 0;
}

.dd-header-flat {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  line-height: 18px;
  font-size: 14px;
  height: 24px;
  max-height: 24px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #677274;
  border-radius: 5px 5px 0 0;
  background-color: white;
  cursor: pointer;
  padding-right: 10px;
}

.dd-header-small,
.dd-header-large {
  top: -3px;
  width: 100%;
  max-width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid #677274;
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
  padding-right: 10px;
}

.dd-header-small:disabled,
.dd-header-large:disabled {
  cursor: default;
  border: 1px solid #8e8e90;
}

.dd-header-title {
  display: inline-block;
  margin-top: 2px;
  width: calc(100% - 25px);
  max-width: calc(100% - 25px);
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dd-placeholder {
  color: lightgrey;
  font-weight: 400;
}

.dd-placeholder-blue {
  color: #0075db;
  font-weight: 400;
}

.dd-placeholder-black {
  color: #000;
  font-weight: 400;
}

.dd-placeholder-disabled {
  color: #8e8e90;
}

.dd-header-blue {
  color: #0075db;
  border: 1px solid #0075db;
  background-color: white;
}

.dd-divider {
  position: absolute;
  display: inline-block;
  right: 33px;
  margin: -2px 0;
  width: 0;
  border-left: 1px solid #677274;
  height: 30px;
}

.dd-divider-blue {
  border-left: 1px solid #0075db;
}

.dd-icon-div {
  position: relative;
  left: 6px;
  top: 2px;
}

.dd-icon-up {
  transform: rotate(180deg) translateY(0);
}

.dd-wrapper > span {
  display: flex;
  margin-right: 20px;
}

.dd-list {
  position: absolute;
  z-index: 20;
  min-width: calc(100% - 12px);
  width: auto;
  max-height: 215px;
  border: 1px solid #677274;
  border-radius: 3px;
  box-shadow: 0 2px 5px -1px #677274;
  background-color: white;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  padding: 5px;
  overflow-y: auto;
  margin-top: 32px;
}

.dd-list-justify-right {
  right: 0;
}

.dd-list-above {
  top: unset;
  bottom: 32px;
}

.dd-list-item {
  position: relative;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  margin-left: 3px;
  width: auto;
  min-width: calc(100% - 5px);
  height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: white;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  line-height: 14px;
}

.dd-list-item.dd-multi-selected {
  background-color: lightgrey;
  border: 1px solid white;
  color: white;
}

.dd-list-item.dd-selected:hover {
  background-color: lightgrey;
  color: white;
}

.dd-list-item:hover {
  background-color: #0075db;
  color: white;
}

.dd-list-item-disabled:hover {
  background-color: white !important;
  color: lightgrey !important;
}

.dd-checkbox {
  position: relative;
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  border: 1px solid #aaaaaa !important;
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  vertical-align: middle;
  margin-top: -4px;
}

.dd-icon-blue > svg > path {
  filter: invert(28%) sepia(43%) saturate(6170%) hue-rotate(195deg) brightness(97%) contrast(100%);
}

.dd-checked {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 43%;
  transform: translate(-50%, -50%);
}

.dd-no-border {
  border: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL01lbnUiLCJzb3VyY2VzIjpbIm1lbnUuc2NzcyIsIi4uLy4uL2RlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBS0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7QUFBQTtFQUVFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7QUFBQTtFQUVFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFLE9DdEhhO0VEdUhiOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0UsT0NwSWE7RURxSWI7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFLGtCQ2pPYTtFRGtPYjs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0UifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
