import React, { useEffect, useState } from 'react';

import './page.scss';
import { Header } from '../../components/Header';
import { Dropdown, DropdownItem, DropdownItemFunction } from '../../components/Dropdown';
import { Card } from '../Card';
import { Info } from '../../components/Info';
import { Modal } from '../../components/Modal';
import { Icon } from '../../components/Icon';
import { MAIN_INFO_TEXT } from '../../constants';
import { getSchools, getCounselors, getClassYears } from '../../api';

export interface Props {
  appDisplayName: string;
  isDistrictLevel: boolean;
}

export const Page = (props: Props): JSX.Element => {
  (Page as React.FC).displayName = 'Page';
  const { appDisplayName, isDistrictLevel } = props;
  const [schools, setSchools] = useState<DropdownItem[]>([]);
  const [counselors, setCounselors] = useState<DropdownItem[]>([]);
  const [classYears, setClassYears] = useState<DropdownItem[]>([]);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState<DropdownItem>();
  const [selectedCounselor, setSelectedCounselor] = useState<DropdownItem>();
  const [selectedClassYear, setSelectedClassYear] = useState<DropdownItem>();

  const retrieveSchools = async () => {
    const schools = await getSchools();
    setSchools(schools);
    setSelectedSchool(schools[0]);
  };

  const retrieveCounselors = async () => {
    const counselors = await getCounselors();
    setCounselors(counselors);
    setSelectedCounselor(counselors[0]);
  };

  const retrieveClassYears = async () => {
    const classYears = await getClassYears();
    setClassYears(classYears);
    setSelectedClassYear(classYears[0]);
  };

  const getSelectedSchool: DropdownItemFunction = (selected: DropdownItem) => {
    schools && selected && setSelectedSchool(selected);
  };

  const getSelectedCounselor: DropdownItemFunction = (selected: DropdownItem) => {
    counselors && selected && setSelectedCounselor(selected);
  };

  const getSelectedClassYear: DropdownItemFunction = (selected: DropdownItem) => {
    classYears && selected && setSelectedClassYear(selected);
  };

  const toggleInfoVisible = () => {
    setInfoVisible(!isInfoVisible);
  };

  useEffect(() => {
    isDistrictLevel ? retrieveSchools() : retrieveCounselors();
    retrieveClassYears();
  }, []);

  return (
    <div className="srt-main">
      <div className="srt-banner">
        <div className="srt-app-name-info">
          <Header appDisplayName={appDisplayName} />
          <Info showInfo={toggleInfoVisible} />
        </div>
        <Modal isVisible={isInfoVisible} toggleVisibility={toggleInfoVisible}>
          <p>{MAIN_INFO_TEXT}</p>
          <Icon name="check" />
        </Modal>
        <div className="banner-dropdowns">
          {isDistrictLevel && schools && (
            <Dropdown list={schools} getSelected={getSelectedSchool} />
          )}
          {!isDistrictLevel && counselors && (
            <Dropdown list={counselors} getSelected={getSelectedCounselor} />
          )}
          {classYears && <Dropdown list={classYears} getSelected={getSelectedClassYear} />}
        </div>
      </div>
      <Card
        isDistrictLevel={isDistrictLevel}
        selectedSchool={selectedSchool}
        selectedCounselor={selectedCounselor}
        selectedClassYears={selectedClassYear}
        selectedClassYear={selectedClassYear}
        schools={schools}
        counselors={counselors}
      />
    </div>
  );
};
