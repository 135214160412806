import { apiClient } from '../utils/apiClient';
import { GET_CLASS_YEARS_ENDPOINT } from '../constants';
import { DropdownItem } from '../components/Dropdown';

export const getClassYears = async (): Promise<DropdownItem[]> => {
  const classYears = await apiClient({ url: GET_CLASS_YEARS_ENDPOINT, method: 'GET' });
  return classYears.map((y: number) => {
    const newClassYear: DropdownItem = {
      key: `${y}`,
      value: `Class of ${y}`,
    };
    return newClassYear;
  });
};
