const css = `.tableWrap {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.column-dropdown {
  margin-top: 5px;
}

.table {
  margin-top: 20px;
  width: 100%;
  overflow: auto !important;
  min-height: 275px;
  margin-bottom: 0;
  flex-grow: 1;
}
.table .tr {
  width: auto !important;
}
.table .tr :last-child .td {
  border-bottom: 0;
}
.table .th:not([data-sticky-td]),
.table .td:not([data-sticky-td]) {
  flex-grow: 1;
  flex-shrink: 1;
}
.table .td:not([data-sticky-td]) {
  background-color: #f5f8ff;
}
.table .th:nth-child(1),
.table .td:nth-child(1) {
  width: 150px !important;
  max-width: 150px;
  min-width: 150px;
}
.table .th:nth-child(2),
.table .td:nth-child(2) {
  width: 100px !important;
  max-width: 100px;
  min-width: 100px;
  left: 150px !important;
}
.table .th:nth-child(3),
.table .td:nth-child(3) {
  width: 150px !important;
  max-width: 150px;
  min-width: 150px;
  left: 250px !important;
  border-right: 1px solid #ddd;
}
.table .th:nth-child(4),
.table .td:nth-child(4) {
  left: 400px !important;
  border-right: 1px solid #ddd;
}
.table .th:last-child,
.table .td:last-child {
  width: 80px !important;
  max-width: 80px;
  min-width: 80px;
  border-right: 1px solid #ddd;
}
.table .th:last-child .sri-icon-inner,
.table .td:last-child .sri-icon-inner {
  text-align: center;
}
.table .th {
  font-weight: bold;
  background-color: #ebebeb;
}
.table .th > div {
  display: flex !important;
  justify-content: space-between;
}
.table .th:hover {
  background-color: #d6d6d6;
}
.table .td {
  background-color: #fff;
}
.table .th,
.table .td {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  min-width: 150px;
}
.table .th :last-child,
.table .td :last-child {
  border-right: 0;
}
.table .th .resizer,
.table .td .resizer {
  display: inline-block;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
}
.table .th .resizer.isResizing,
.table .td .resizer.isResizing {
  background: red;
}
.table.sticky {
  overflow: scroll;
}
.table.sticky .header,
.table.sticky .footer {
  position: sticky;
  z-index: 1;
  width: fit-content;
}
.table.sticky .header {
  top: 0;
  box-shadow: 0 3px 3px #ccc;
  width: 100%;
  min-width: fit-content;
  overflow: visible !important;
}
.table.sticky .header .th {
  overflow: visible !important;
}
.table.sticky .footer {
  bottom: 0;
  box-shadow: 0 -3px 3px #ccc;
}
.table.sticky .body {
  position: relative;
  z-index: 0;
  min-width: fit-content;
}
.table.sticky [data-sticky-td] {
  position: sticky;
}
.table.sticky [data-sticky-last-left-td] {
  box-shadow: 2px -2px 3px #ccc;
}
.table.sticky [data-sticky-first-right-td] {
  box-shadow: -2px 0 3px #ccc;
}
.table .td:nth-child(4):not([data-sticky-td]) {
  background-color: transparent !important;
}

.column-header-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sort-arrow {
  display: inline-block;
}

.dropdown-filter {
  padding-top: 3px;
  width: 100%;
}

.header-dark-background {
  background-color: #d6d6d6 !important;
}

.pagination {
  display: flex;
  position: relative;
  height: 49px;
  left: 0%;
  right: 0.09%;
  top: 1px;
  justify-content: space-between;
  align-items: center;
}
.pagination > * {
  display: flex;
  align-items: center;
}
.pagination > * > * {
  margin-left: 10px;
  margin-right: 10px;
}
.pagination .items-per-page {
  height: 22px;
  left: 0px;
  top: 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #272728;
}
.pagination .divider {
  width: 1.16px;
  height: 49px;
  top: 1px;
  background: #e4e6eb;
}
.pagination .show-items {
  height: 18px;
  left: 234px;
  top: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #515152;
}
.pagination .dd-wrapper {
  margin-top: 9px;
  margin-left: 1px;
}
.pagination .show-items-per-page {
  height: 22px;
  top: 12px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #272728;
  display: flex;
}
.pagination .show-items-per-page .dd-wrapper {
  margin-top: -2px;
  margin-left: 2px;
}
.pagination .dd-icon-div {
  padding-top: 6px;
}

.page-number-select-wrapper {
  width: 60px;
  display: inline-flex;
}

.empty-student-data-text {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 40%;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 50, 50);
}

.table-icon-cell {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.table-icon-button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}

.disabled {
  pointer-events: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb250YWluZXJzL1RhYmxlV2l0aEZpeGVkQ29sdW1uIiwic291cmNlcyI6WyJ0YWJsZVdpdGhGaXhlZENvbHVtbi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUVFO0VBQ0U7O0FBS047QUFBQTtFQUVFO0VBQ0E7O0FBRUY7RUFDRTs7QUFHRjtBQUFBO0VBRUU7RUFDQTtFQUNBOztBQUVGO0FBQUE7RUFFRTtFQUNBO0VBQ0E7RUFDQTs7QUFFRjtBQUFBO0VBRUU7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFRjtBQUFBO0VBRUU7RUFDQTs7QUFFRjtBQUFBO0VBRUU7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7QUFBQTtFQUNFOztBQUlKO0VBQ0U7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBR0o7RUFDRTs7QUFFRjtFQUNFOztBQUdGO0FBQUE7RUFFRTtFQUNBO0VBRUE7O0FBRUE7QUFBQTtFQUNFOztBQUdGO0FBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0FBQUE7RUFDRTs7QUFLTjtFQUNFOztBQUNBO0FBQUE7RUFFRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBSUo7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUlKO0VBQ0U7OztBQUlKO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTs7QUFJSjtFQUNFOzs7QUFJSjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0UifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
