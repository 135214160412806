import './dropdown.scss';
import React, { useEffect, useState, MouseEvent } from 'react';
import { Icon, IconColor } from '../Icon';

export type DropdownItem = {
  key?: string;
  value?: string;
  depth?: number;
  hidden?: boolean;
};

export type DropdownItemMulti = {
  values?: DropdownItem[];
};

export type DropdownItemFunction = (selected: DropdownItem) => DropdownItem | void;
export type DropdownMultiFunction = (selected: DropdownItemMulti) => DropdownItemMulti | void;

interface DropdownProps {
  list: DropdownItem[];
  getSelected: DropdownItemFunction | DropdownMultiFunction;
  size?: 'flat' | 'small' | 'large' | 'x-small' | 'full';
  color?: string;
  icon?: string;
  iconColor?: IconColor;
  withDivider?: boolean;
  placeHolderColor?: 'blue' | 'black';
  placeholder?: string;
  multiselect?: boolean;
  firstItemSelectsAll?: boolean;
  checkboxes?: boolean;
  hideBorder?: boolean;
  position?: 'below' | 'above';
}

const emptySelects: string[] = [];

export const Dropdown = (props: DropdownProps): JSX.Element => {
  (Dropdown as React.FC).displayName = 'Dropdown';
  const {
    list,
    getSelected,
    size,
    color,
    icon,
    iconColor,
    withDivider,
    placeholder,
    placeHolderColor,
    multiselect,
    firstItemSelectsAll,
    checkboxes,
    hideBorder,
    position = 'below',
  } = props;

  const placeholderObject: DropdownItem = { key: placeholder || '', value: placeholder || '' };
  const [isListOpen, setListOpen] = useState<boolean>(false);
  const [option, setOption] = useState<DropdownItem>(placeholder ? placeholderObject : list[0]);
  const [selectedKeys, setSelectedKeys] = useState(emptySelects);

  const sizeClass = size && size !== 'small' ? `dd-${size}` : `dd-small`;
  const sizeHeaderClass = size && size === 'flat' ? `dd-header-${size}` : `dd-header-small`;
  const colorClass = color ? `dd-header-${color}` : '';
  const colorDividerClass = color ? `dd-divider-${color}` : '';
  const defaultValue = list && list[0] ? list[0].value : '';
  const defaultDisplayText = placeholder ? placeholder : defaultValue;
  const placeholderColorClass =
    placeholder && option?.value === placeholder
      ? `dd-placeholder dd-placeholder-${placeHolderColor}`
      : '';
  const selectedClass =
    multiselect && !checkboxes ? 'dd-selected dd-multi-selected' : 'dd-selected';
  const listPositionClass = position === 'above' ? 'dd-list-above' : 'dd-list-below';
  let otherHeaderClasses = '';
  if (hideBorder) {
    otherHeaderClasses += 'dd-no-border ';
  }
  const closeList = () => {
    window.removeEventListener('click', closeList);
    setListOpen(false);
  };

  const toggleList = (e: MouseEvent) => {
    if (!isListOpen) {
      window.dispatchEvent(new Event('click'));
    }
    setListOpen(!isListOpen);
    e.stopPropagation();
  };

  const selectOption = (e: MouseEvent) => {
    e.stopPropagation();
    const target = e.currentTarget as HTMLElement;
    const key = target.getAttribute('data-key');
    const value = target.getAttribute('data-value');
    const selectedItem = { key, value };
    setOption(selectedItem as DropdownItem);
    toggleList(e);
  };

  const addRemoveOption = (e: MouseEvent) => {
    e.stopPropagation();
    const target = e.currentTarget as HTMLElement;
    const key = target.getAttribute('data-key');
    let selected = selectedKeys;
    if (selectedKeys.includes(key as string) || (firstItemSelectsAll && key === list[0].key)) {
      if (firstItemSelectsAll && key === list[0].key) {
        selected = selectedKeys.includes(key as string)
          ? []
          : list.map((item) => {
              return item?.key || '';
            });
      } else {
        selected = selectedKeys.filter((aKey) => {
          if (firstItemSelectsAll) {
            return aKey !== key && aKey !== list[0].key;
          }
          return aKey !== key;
        });
      }
    } else {
      selected = [...selectedKeys, key as string];
      if (firstItemSelectsAll && selected.length === list.length - 1) {
        selected = [...selected, list[0].key as string];
      }
    }
    setSelectedKeys(selected);
    // toggleList(e);
  };

  const isSelected = (item: DropdownItem) => {
    if (multiselect) {
      return selectedKeys.includes(item.key as string);
    } else {
      return item.value === (option ? option.value : list[0].value);
    }
  };

  useEffect(() => {
    if (isListOpen) {
      window.addEventListener('click', closeList);
    } else {
      window.removeEventListener('click', closeList);
    }
    if (multiselect) {
      const newSelectedItems: DropdownItem[] = list.filter((item: DropdownItem) => {
        return selectedKeys.includes(`${item.key}`);
      });
      const newSelection: DropdownItemMulti = { values: newSelectedItems };
      getSelected(newSelection);
    } else {
      getSelected(option);
    }
  }, [isListOpen]);

  const getHeader = () => {
    let classes = `dd-header-title ${placeholderColorClass}`;
    let content = defaultDisplayText;
    if (multiselect && selectedKeys.length > 0) {
      content = selectedKeys.reduce((prevVal, curVal, curIn) => {
        if (curIn > 0) {
          prevVal += ', ';
        }
        return prevVal + list.find((i) => i.key === curVal)?.value;
      }, '');
      classes = 'dd-header-title';
    } else if (option) {
      content = option.value;
    }
    return <div className={`${classes}`}>{content}</div>;
  };

  return (
    <div className={`dd-wrapper ${sizeClass}`}>
      {list.length > 0 && (
        <button
          data-cy="dropdown-button"
          type="button"
          className={`${sizeHeaderClass} ${colorClass} ${otherHeaderClasses}`}
          onClick={(e) => toggleList(e)}
        >
          {getHeader()}
          {withDivider && <span className={`dd-divider ${colorDividerClass}`} />}
          <div className={`dd-icon-div ${isListOpen ? 'dd-icon-up' : 'dd-icon-down'}`}>
            <Icon name={`${icon || 'triangle'}`} color={`${iconColor || 'grey'}` as IconColor} />
          </div>
        </button>
      )}
      {isListOpen && (
        <div role="list" className={'dd-list ' + listPositionClass}>
          {list.map((item: DropdownItem) => (
            <React.Fragment key={`${item.key}-fragment`}>
              {!item.hidden && (
                <button
                  type="button"
                  className={isSelected(item) ? `dd-list-item ${selectedClass}` : 'dd-list-item'}
                  key={`${item.key}`}
                  data-key={`${item.key}`}
                  data-value={`${item.value}`}
                  onClick={(e) => (multiselect ? addRemoveOption(e) : selectOption(e))}
                >
                  {multiselect && checkboxes && (
                    <div className="dd-checkbox">
                      <div className="dd-checked">
                        {isSelected(item) && <Icon name="checked" color="blue" />}
                      </div>
                    </div>
                  )}
                  {item.depth
                    ? Array(item.depth * 2)
                        .fill(1)
                        .map((e, i) => <span key={`${item.key}-${i}-indent`}>&nbsp;</span>)
                    : ''}
                  {item.value}
                </button>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
