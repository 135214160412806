const css = `.status-selector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 172px;
  height: 32px;
}

.status-selector-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.status-selector-button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px;
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #0075db;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.status-selector-button-group .button {
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 16px;
  position: static;
  width: 24px;
  height: 24px;
  top: 1px;
  flex: none;
  flex-grow: 0;
  background: #ffffff;
  cursor: pointer;
  box-sizing: content-box;
}
.status-selector-button-group .button-disabled {
  cursor: default;
}
.status-selector-button-group .active {
  background: #0075db;
}
.status-selector-button-group .right {
  left: 115px;
  border-radius: 0px 3px 3px 0px;
  order: 2;
  margin: 0px;
}
.status-selector-button-group .center {
  left: 58px;
  border-radius: 0px;
  order: 1;
  margin: 0px 1px 0px 0px;
}
.status-selector-button-group .left {
  left: 1px;
  border-radius: 3px 0px 0px 3px;
  order: 0;
  margin: 0px 1px 0px 0px;
}

.status-selector-button-group.selector-disabled {
  background: #677274;
}
.status-selector-button-group.selector-disabled .button-disabled.active {
  background: #677274;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL1N0YXR1c1NlbGVjdG9yIiwic291cmNlcyI6WyJzdGF0dXNTZWxlY3Rvci5zY3NzIiwiLi4vLi4vZGVmaW5pdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSxZQ3hDYTtFRHlDYjtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRSxZQ3RFVzs7QUR5RWI7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7OztBQUlKO0VBQ0UsWUMxRlk7O0FEMkZaO0VBQ0UsWUM1RlUifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
