const css = `.title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.name-label {
  text-anchor: end;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  fill: #333333;
  margin: 0;
}

.name-label-p {
  color: #333333;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.value-label {
  text-anchor: end;
  fill: #333333;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-left: 13.5px;
}

.bar {
  fill: url(#LinearGradientBar);
}

.line {
  stroke: #677274;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdGFmZi9jb21wb25lbnRzL0dyYXBoIiwic291cmNlcyI6WyJiYXJHcmFwaC5zY3NzIiwiLi4vLi4vZGVmaW5pdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLE9DSWlCO0VESGpCO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EsTUNSaUI7RURTakI7OztBQUdGO0VBQ0UsT0NiaUI7RURjakI7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0EsTUMzQmlCO0VENEJqQjtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFLFFDekNZIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
