import './tableWithFixedColumn.scss';
import React from 'react';
import {
  useTable,
  useBlockLayout,
  useSortBy,
  useFilters,
  Row,
  Column,
  HeaderGroup,
  UseSortByColumnProps,
  UseFiltersColumnProps,
  Cell,
  usePagination,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Dropdown, DropdownItem, DropdownItemMulti } from '../../components/Dropdown';
import { svgs, Icon } from '../../components/Icon';
import { STATUS, STATUSES } from '../../constants';

interface TableProps {
  indicator: DropdownItem;
  columns: Column<any>[];
  data: unknown[];
  schools?: DropdownItem[];
  counselors?: DropdownItem[];
  onAction: any;
}

interface DropdownFilterProps {
  column: {
    filterValue: string;
    preFilteredRows: Row[];
    setFilter: (value?: any) => void;
    id: string;
  };
}

// Define a default UI for filtering
const DefaultColumnFilter = () => {
  (DefaultColumnFilter as React.FC).displayName = 'DefaultColumnFilter';
  return <div />;
};

// This is a custom filter UI for selecting
// a unique option from a list
const getDropdownFilter = (placeholder: string, multiselect: boolean, list?: DropdownItem[]) => {
  const DropdownFilter = (props: React.PropsWithChildren<DropdownFilterProps>): JSX.Element => {
    (DropdownFilter as React.FC).displayName = 'DropdownFilter';
    const {
      column: { setFilter },
    } = props;

    const filterItems = (item: DropdownItem | DropdownItemMulti) => {
      if (multiselect) {
        setFilter((item as DropdownItemMulti).values?.map((v: DropdownItem) => v.key));
      } else {
        setFilter((item as DropdownItem).value);
      }
    };

    return (
      <div className="dropdown-filter">
        <Dropdown
          list={list || []}
          getSelected={filterItems}
          placeholder={placeholder}
          size="flat"
          icon="angle"
          color="grey"
          multiselect={multiselect}
          checkboxes={multiselect}
        />
      </div>
    );
  };
  return DropdownFilter;
};

export const TableWithFixedColumns = (props: React.PropsWithChildren<TableProps>): JSX.Element => {
  (TableWithFixedColumns as React.FC).displayName = 'TableWithFixedColumns';

  const { columns, data, schools, counselors, onAction } = props;
  const mainColumn =
    schools && schools.length > 1
      ? schools
      : [
          ...(counselors as DropdownItem[]),
          {
            key: '1',
            value: 'Unassigned',
          },
        ];
  const mainColumnName = schools && schools.length > 1 ? 'Select School' : 'Select Counselor';

  const defaultColumn: any = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  function filterIncludes(rows: Row[], ids: Array<any>, filterValue: string | string[]) {
    const isMultiSelect = typeof filterValue === 'object';

    if (isMultiSelect) {
      const showAll = filterValue.length < 1 || filterValue.includes(STATUS.ALL);
      if (!showAll) {
        return rows.filter((row: Row<any>) =>
          filterValue.includes(row?.original[ids[0]] as string)
        );
      }
    } else {
      let showAll = true;
      if (ids.includes('school')) {
        showAll = filterValue === 'Select School' || filterValue === 'All Schools';
      } else if (ids.includes('counselor')) {
        showAll = filterValue === 'Select Counselor' || filterValue === 'All Counselors';
      }
      if (!showAll) {
        return rows.filter((row: Row<any>) => row.original[ids[0]] === filterValue);
      }
    }

    return rows;
  }

  columns.forEach((column: any, i: number) => {
    if (i === 2) {
      column.Filter = getDropdownFilter(mainColumnName, false, mainColumn);
      column.filter = filterIncludes;
    }
    if (i === 3 && columns.length > 3) {
      column.sticky = 'left';
    }
    if (i > 2 && column.accessor !== 'actions') {
      column.Filter = getDropdownFilter('Select Status', true, STATUSES);
      column.filter = filterIncludes;
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<any>(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    useFilters,
    useSortBy,
    useBlockLayout,
    usePagination,
    useSticky
  );

  const pageOptionItems = Array.from(Array(pageOptions.length).keys()).map((index) => index + 1);

  return (
    <div className="tableWrap">
      <div {...getTableProps()} className="table sticky">
        <div className="header">
          {headerGroups.map((headerGroup: HeaderGroup<any>, i: number) => (
            <div {...headerGroup.getHeaderGroupProps()} key={i} className="tr">
              {headerGroup.headers.map((header: HeaderGroup<any>, j: number) => {
                const headerClasses = (header as unknown as UseSortByColumnProps<any>).isSorted
                  ? 'th header-dark-background'
                  : 'th';
                return (
                  <div
                    {...header.getHeaderProps(
                      (header as unknown as UseSortByColumnProps<any>).getSortByToggleProps()
                    )}
                    key={j}
                    className={headerClasses}
                  >
                    <div>
                      <span className="column-header-text">{header.render('Header')}</span>
                      {(header as unknown as UseSortByColumnProps<any>).isSorted ? (
                        <span className="sort-arrow">
                          {(header as unknown as UseSortByColumnProps<any>).isSortedDesc ? (
                            <Icon name="sortUp" size={16} />
                          ) : (
                            <Icon name="sortDown" size={16} />
                          )}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      {(header as unknown as UseFiltersColumnProps<any>).canFilter
                        ? header.render('Filter')
                        : null}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body">
          {page.map((row: Row<any>, i: number) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} key={i} className="tr">
                {row.cells.map((cell: Cell<any>, j: number) => {
                  return (
                    <div {...cell.getCellProps()} key={j} className="td">
                      {!svgs[cell.value] && cell.column.id !== 'actions' && cell.render('Cell')}
                      {cell.column.id === 'actions' && (
                        <div className="table-icon-cell" onClick={onAction(row)}>
                          <Icon name="pencil" />
                        </div>
                      )}
                      {svgs[cell.value] && (
                        <div className="table-icon-cell">
                          <Icon name={cell.value} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {rows.length == 0 && (
          <div className="empty-student-data-text">
            <span>Your search / filter selections didn’t return any results.</span>
          </div>
        )}
      </div>
      <div className="pagination">
        <div>
          <div className="items-per-page">Items per page:</div>
          <div>
            <Dropdown
              list={[20, 30, 50, 100].map((i) => ({ key: '' + i, value: '' + i }))}
              getSelected={(e: any) => {
                setPageSize(Number(e.value));
              }}
              size="full"
              icon="chevronDown"
              iconColor="blue"
              position="above"
              hideBorder
              placeHolderColor="black"
            />
          </div>
          <div className="divider"></div>
          <div className="show-items">
            {(pageIndex > 1 ? pageIndex : 1) * (pageIndex ? pageSize : 0) + 1}-
            {(pageIndex + 1) * pageSize > rows.length ? rows.length : (pageIndex + 1) * pageSize}
            &nbsp; of {rows.length} students
          </div>
        </div>

        <div>
          <div className="divider"></div>
          <div className="show-items-per-page">
            <div className="page-number-select-wrapper">
              <Dropdown
                list={pageOptions.map((i) => ({ key: '' + (i + 1), value: '' + (i + 1) }))}
                getSelected={(e: any) => {
                  if (e) {
                    gotoPage(Number(e.value - 1));
                  }
                }}
                key={pageIndex}
                placeholder={'' + (pageIndex + 1)}
                size="full"
                icon="chevronDown"
                iconColor="blue"
                position="above"
                hideBorder
                placeHolderColor="black"
              />
            </div>
            <span>&nbsp; of {pageOptions.length} pages </span>
          </div>
          <div className="divider"></div>
          <div
            onClick={() => {
              if (canPreviousPage) {
                previousPage();
              }
            }}
          >
            <Icon name="caretLeft" size={16} />
          </div>

          <div className="divider"></div>
          <div
            onClick={() => {
              if (canNextPage) {
                nextPage();
              }
            }}
          >
            <Icon name="caretRight" size={16} />
          </div>
        </div>
      </div>
    </div>
  );
};
