const css = `.card-wrapper {
  display: block;
  position: relative;
  padding: 1px 1px 10px 0;
  background-color: #e5e5e5;
  height: 100vh;
}

.card {
  display: block;
  position: relative;
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px -1px #677274;
  min-height: 785px;
}

.card-content {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.indicator-name {
  margin: 15px 8px 5px 8px;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}

@media screen and (min-width: 990px) {
  .card-wrapper {
    height: 100%;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdGFmZi9jb250YWluZXJzL0NhcmQiLCJzb3VyY2VzIjpbImNhcmQuc2NzcyIsIi4uLy4uL2RlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQSxrQkNEVztFREVYOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBLE9DM0JpQjs7O0FEOEJuQjtFQUNFO0lBQ0UifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
